import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {TranslationPortalFile} from "../../utils/constants";
import {Action, ActionsMenu, AlertSeverity, Color, IconTooltip, Loadable, StatusLabel, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {Order} from "../../api/model/order/Order";
import {OrderStatus} from "../../api/model/order/OrderStatus";
import {Formatter} from "../../common/utils/Formatter";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {downloadCSV} from "../../common/utils/portal";
import {session} from "../../api/ApiSession";

export type OrderTableRowActions = {
    onCancel: () => void;
}

type OrderTableRowProps = {
    order: Order;
    actions?: OrderTableRowActions;
    isHome?: boolean;
}

const OrderTableRow: FunctionComponent<OrderTableRowProps> = ({order, actions, isHome}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textOrders} = useTranslation(TranslationPortalFile.ORDERS);
    const history = useHistory();
    const [isLoadingDownload, setLoadingDownload] = useState(false);

    const getStatusColor = () => {
        switch (order.status) {
            case OrderStatus.ACCEPTED:
                return Color.GREEN;
            case OrderStatus.REJECTED:
            case OrderStatus.CANCELED:
                return Color.ORANGE;
            default:
                return Color.GREY;
        }
    };

    const downloadOrder = async () => {
        try {
            setLoadingDownload(true);
            const orderCsv = await session.restOrder.downloadAcceptedOrder(order.id.toString());

            let universalBOM = "\uFEFF";
            let csvFile = universalBOM + orderCsv;
            downloadCSV(order.comment.toString().split(".")[0], csvFile);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(textOrders("error.download_data", {message: e.message}), AlertSeverity.DANGER);
            }
        } finally {
            setLoadingDownload(false);
        }
    };

    return (
        <TableRow onClick={{link: order.getRoute()}}>
            <TableColumn styles={TableColumnStyle.NOWRAP}>
                <StatusLabel label={t(`status.${order.status}`)} icon={{name: "circle", colorIcon: getStatusColor()}}/>
            </TableColumn>
            <TableColumn>{order.id}</TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{order.comment}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{Formatter.formatDate(order.creation_ts)}</TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{!!order.table?.row_count ? Formatter.formatNumber(order.table.row_count) : "-"}</TableColumn>
            {!isHome ? <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{!!order.ip_address.length ? order.ip_address : "-"}</TableColumn> : <></>}
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{!!order.cost ? `${Formatter.formatNumber(order.cost)}${order.getOrderCurrencySymbol()}` : "-"}</TableColumn>
            {!!actions ?
                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                    {order.isDownloadable() &&
                        <Loadable loading={isLoadingDownload} loaderOptions={{small: true}}>
                            <IconTooltip
                                icon={Action.DOWNLOAD.icon}
                                text={textOrders("actions.download_data")}
                                onClick={downloadOrder}
                            />
                        </Loadable>
                    }
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                        items={order.isCancellable() ? [
                            {label: textOrders("actions.view_order"), onClick: () => history.push(new Order(order).getRoute())},
                            {label: textOrders("actions.cancel_order"), onClick: actions.onCancel, critical: true, separator: true}
                        ] : [
                            {label: textOrders("actions.view_order"), onClick: () => history.push(new Order(order).getRoute())}
                        ]}
                    />
                </TableActionColumn> : <></>
            }
        </TableRow>
    );
};

export default OrderTableRow;