import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {pathCountings, pathOrders, pathStorages, TranslationPortalFile} from "../utils/constants";
import {Storage} from "../api/model/storage/Storage";
import {session} from "../api/ApiSession";
import {StoragesQuery} from "../api/model/storage/search/StoragesQuery";
import {StorageSortType} from "../api/model/storage/search/StorageSortType";
import {MainContent, Wrapper} from "../common/component/widget";
import {MainHeader, NotificationCarousel} from "../common/component/snippet";
import {
    AlertSeverity, Box, BoxProps, Button, ButtonAdd, ButtonSize, ButtonStyle, ContentBlock, FlexContent, FlexContentDirection, FlexContentLayout,
    FlexContentMobileDirection, FlexContentSpacing, LayoutRows, Loadable, Paragraph, ParagraphAlignment, Table, TableColumnStyle
} from "@sirdata/ui-lib";
import {useHistory} from "react-router-dom";
import {OrderTableRow, StorageTableRow} from "../component/snippet";
import {RequestedOrdersQuery} from "../api/model/order/search/RequestedOrdersQuery";
import {OrderSortOrder} from "../api/model/order/search/OrderSortOrder";
import {Order} from "../api/model/order/Order";
import {OrderStatus} from "../api/model/order/OrderStatus";
import {StorageFilterListQuery} from "../api/model/storage-filter/search/StorageFilterListQuery";
import {StorageFilter} from "../api/model/storage-filter/StorageFilter";
import CountingTableRow from "../component/snippet/CountingTableRow";
import {SvgStorages} from "../component/svg";
import {UIEventManager} from "../common/utils/UIEventManager";
import {ModalContactUiEvent} from "../common/component/modal/ModalContact";
import {ModalUploadStorage} from "../component/modal";
import {ErrorResponse} from "../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../common/api/http/HttpStatusCode";

const MAX_ROW_COUNT = 3;

function Home() {
    const {t: textHome} = useTranslation(TranslationPortalFile.HOME);
    const {t: textStorages} = useTranslation(TranslationPortalFile.STORAGES);
    const {t: textCountings} = useTranslation(TranslationPortalFile.COUNTINGS);
    const {t: textOrders} = useTranslation(TranslationPortalFile.ORDERS);
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [isLoadingStorages, setLoadingStorages] = useState(false);

    const [latestStorages, setLatestStorages] = useState<Storage[]>([]);
    const [latestCountings, setLatestCountings] = useState<StorageFilter[]>([]);
    const [latestOrders, setLatestOrders] = useState<Order[]>([]);

    const [isShowModalUploadStorage, setShowModalUploadStorage] = useState<boolean>(false);

    const loadStorages = useCallback(async () => {
        try {
            setLoadingStorages(true);
            const storages = await session.restStorage.getStorages(new StoragesQuery({sort_by: StorageSortType.RECENCY.field, size: MAX_ROW_COUNT}));
            setLatestStorages(storages);
        } catch (e) {
            if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                UIEventManager.alert(textStorages("error.get_storages"), AlertSeverity.DANGER);
            }
        } finally {
            setLoadingStorages(false);
        }
    }, [textStorages]);

    useEffect(() => {
        (async function () {
            await loadStorages();
        })();
    }, [loadStorages]);

    useEffect(() => {
        (async function () {
            try {
                const countings = await session.restStorageFilter.getStorageFilters(new StorageFilterListQuery({size: MAX_ROW_COUNT}));
                setLatestCountings(countings);

                const orders = await session.restOrder.getRequestedOrders(new RequestedOrdersQuery({size: MAX_ROW_COUNT, sort_order: OrderSortOrder.DESCENDANT, status: [OrderStatus.ACCEPTED, OrderStatus.PENDING, OrderStatus.PROCESSING, OrderStatus.WAITING_VALIDATION].join(",")}));
                setLatestOrders(orders);
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, [history]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <LayoutRows cssClass="home">
                    <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.LARGE_PLUS} layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <LayoutRows>
                            <NotificationCarousel cssClass="home__notification-carousel"/>
                            <Loadable loading={isLoading}>
                                <Loadable loading={isLoadingStorages}>
                                    {latestStorages.length > 0 ?
                                        <ContentBlock
                                            header={{
                                                title: {label: textHome("latest_storages.title", {count: latestStorages.length}).toUpperCase()},
                                                actions: <ButtonAdd key="add_storage" onClick={() => setShowModalUploadStorage(true)} size={ButtonSize.MEDIUM}/>
                                            }}
                                            footer={{link: pathStorages, label: textHome("latest_storages.see_my_storages")}}
                                        >
                                            <Table
                                                columns={[
                                                    {label: textStorages("table.headers.status"), width: 15},
                                                    {label: textStorages("table.headers.id"), width: 10},
                                                    {label: textStorages("table.headers.name"), width: 45},
                                                    {label: textStorages("table.headers.creation_date"), width: 15, styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                                    {label: textStorages("table.headers.size"), width: 15, styles: TableColumnStyle.HIDE_SCREEN_MEDIUM}
                                                ]}
                                            >
                                                {latestStorages.map((storage) =>
                                                    <StorageTableRow key={storage.id} storage={storage}/>)
                                                }
                                            </Table>
                                        </ContentBlock> :
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW} cssClass="home__empty-content">
                                            <div className="empty-content-image">
                                                <SvgStorages/>
                                            </div>
                                            <div className="empty-content-infos">
                                                <Paragraph alignment={ParagraphAlignment.CENTER} withSpacing>
                                                    <span dangerouslySetInnerHTML={{__html: textHome("latest_storages.empty_content.message")}}/>
                                                </Paragraph>
                                                <Button onClick={() => setShowModalUploadStorage(true)} size={ButtonSize.BIG}>
                                                    {textHome("latest_storages.empty_content.button")}
                                                </Button>
                                            </div>
                                        </Box>
                                    }
                                </Loadable>
                                {latestCountings.length > 0 &&
                                    <ContentBlock
                                        header={{title: {label: textHome("latest_countings.title", {count: latestStorages.length}).toUpperCase()}}}
                                        footer={{link: pathCountings, label: textHome("latest_countings.see_my_countings")}}
                                    >
                                        <Table
                                            columns={[
                                                {label: textCountings("table.headers.status"), width: 15},
                                                {label: textCountings("table.headers.id"), width: 10},
                                                {label: textCountings("table.headers.name"), width: 45},
                                                {label: textCountings("table.headers.creation_date"), width: 30, styles: TableColumnStyle.HIDE_SCREEN_MEDIUM}
                                            ]}
                                        >
                                            {latestCountings.map((counting) =>
                                                <CountingTableRow key={counting.id} counting={counting}/>)
                                            }
                                        </Table>
                                    </ContentBlock>
                                }
                                {latestOrders.length > 0 &&
                                    <ContentBlock
                                        header={{title: {label: textHome("latest_orders.title", {count: latestOrders.length}).toUpperCase()}}}
                                        footer={{link: pathOrders, label: textHome("latest_orders.see_my_orders")}}
                                    >
                                        <Table
                                            columns={[
                                                {label: textOrders("field.status"), width: 15},
                                                {label: textOrders("field.id"), width: 10},
                                                {label: textOrders("field.name"), width: 45},
                                                {label: textOrders("field.date"), width: 15, styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                                {label: textOrders("field.nb_contacts"), width: 15},
                                                {label: textOrders("field.cost"), width: 20}
                                            ]}
                                        >
                                            {latestOrders.map((order) =>
                                                <OrderTableRow key={order.id} order={order} isHome/>
                                            )}
                                        </Table>
                                    </ContentBlock>
                                }
                            </Loadable>
                        </LayoutRows>
                        <LayoutRows>
                            <ContentBlock header={{title: {label: textHome("support.title").toUpperCase(), icon: {name: "support"}}}}>
                                <Box cssClass={"home__support"} {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <div className="support__picture">
                                        <img src="/common/images/support/superdata.png" alt="Superdata"/>
                                    </div>
                                    <Paragraph withSpacing>{textHome("support.support_text")}</Paragraph>
                                    <Button size={ButtonSize.MEDIUM} style={ButtonStyle.PRIMARY_GREEN} onClick={() => UIEventManager.emit(ModalContactUiEvent, {})} icon={{name: "chat_bubble", outlined: true}}>
                                        {textHome("support.support_btn")}
                                    </Button>
                                </Box>
                            </ContentBlock>
                        </LayoutRows>
                    </FlexContent>
                </LayoutRows>
                <ModalUploadStorage
                    active={isShowModalUploadStorage}
                    onClose={(refresh) => {
                        setShowModalUploadStorage(false);
                        refresh && loadStorages();
                    }}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Home;
