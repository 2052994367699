import React from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {pathCountings, TranslationPortalFile} from "../../utils/constants";
import {MainContent, Wrapper} from "../../common/component/widget";
import {OrdersTable} from "../../component/snippet";
import {MainHeader} from "../../common/component/snippet";
import {Button, ButtonSize, ContentBlock, LayoutRows, MainContentStyle, MainContentPageHeader} from "@sirdata/ui-lib";

function Orders() {
    const history = useHistory();
    const {t: textOrders} = useTranslation(TranslationPortalFile.ORDERS);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader title={textOrders("section.orders")}/>
                <LayoutRows>
                    <ContentBlock>
                        <OrdersTable
                            emptyContent={{
                                message: textOrders("empty_content.no_orders"),
                                children: (
                                    <Button onClick={() => history.push(pathCountings)} size={ButtonSize.BIG}>
                                        {textOrders("empty_content.order")}
                                    </Button>
                                )
                            }}
                        />
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Orders;
