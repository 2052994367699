import React, {memo, useEffect, useState} from "react";
import {Alert, ModalContent, ModalNew, Table, TableColumn, TableRow} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import {Formatter} from "../../common/utils/Formatter";
import {PricingDetails} from "../../api/model/partner/pricing/PricingDetails";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

const ModalPartnerPricing = () => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textPricing} = useTranslation(TranslationPortalFile.PRICING);

    const [pricingDetails, setPricingDetails] = useState<PricingDetails>(new PricingDetails());
    const [isActive, setActive] = useState(false);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        UIEventManager.addListener(ModalPartnerPricingUiEvent, () => {
            setActive(true);
            (async function() {
                try {
                    setPricingDetails(await session.getPricingDetails());
                } catch (e) {
                } finally {
                    setLoading(false);
                }
            })();
        });

        return () => {
            UIEventManager.removeAllListeners(ModalPartnerPricingUiEvent);
        };
    }, []);

    const handleClose = () => {
        setActive(false);
    };

    return (
        <ModalNew onClose={handleClose} active={isActive} loading={isLoading} keepMounted>
            <ModalContent>
                <h2>{t("menu.rate_card")}</h2>
                <Table
                    columns={[
                        {label: textPricing("table.header.steps")},
                        {label: textPricing("table.header.cpm"), width: 20}
                    ]}
                >
                    {pricingDetails.isStepPricePerUnit() ?
                        pricingDetails.getSteps().map(({min, max, price}, index) =>
                            <TableRow key={`partner-pricing-row-${index.toString()}`}>
                                {max ?
                                    <TableColumn><span dangerouslySetInnerHTML={{__html: textPricing("table.row.from_to", {min: Formatter.formatNumber(min), max: Formatter.formatNumber(max)})}}/></TableColumn> :
                                    <TableColumn><span dangerouslySetInnerHTML={{__html: textPricing("table.row.above", {min: Formatter.formatNumber(min)})}}/></TableColumn>
                                }
                                <TableColumn>{price} €</TableColumn>
                            </TableRow>
                        ) : [
                            <TableRow key={"partner-pricing-row-0"}>
                                <TableColumn>-</TableColumn>
                                <TableColumn>{pricingDetails.getFixedPrice()} €</TableColumn>
                            </TableRow>
                        ]
                    }
                </Table>
                <br/>
                {!pricingDetails.isStepPricePerUnit() && <Alert text={textPricing("fixed_price")}/>}
            </ModalContent>
        </ModalNew>
    );
};

export default memo(ModalPartnerPricing);
export const ModalPartnerPricingUiEvent = "ModalPartnerPricing";
