import React, {FunctionComponent} from "react";
import {Storage} from "../../api/model/storage/Storage";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {Action, ActionsMenu, Color, IconTooltip, StatusLabel, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {useHistory} from "react-router-dom";
import {bytesToSize} from "../../utils/helper";
import {StorageStatus} from "../../api/model/storage/StorageStatus";
import {Formatter} from "../../common/utils/Formatter";

export type StorageTableRowActions = {
    onDelete: () => void;
    onCopy: () => void;
}

type StorageTableRowProps = {
    storage: Storage;
    actions?: StorageTableRowActions;
}

const StorageTableRow: FunctionComponent<StorageTableRowProps> = ({storage, actions}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textStorages} = useTranslation(TranslationPortalFile.STORAGES);
    const history = useHistory();

    const getStatusColor = () => {
        switch (storage.status) {
            case StorageStatus.OK:
                return Color.GREEN;
            case StorageStatus.ERROR:
            case StorageStatus.DELETED:
                return Color.ORANGE;
            default:
                return Color.GREY;
        }
    };

    return (
        <TableRow onClick={{link: storage.getRoute()}}>
            <TableColumn styles={TableColumnStyle.NOWRAP}>
                {storage.isExpired() ?
                    <StatusLabel label={t("status.EXPIRED")} icon={{name: "circle", colorIcon: Color.ORANGE}}/> :
                    <StatusLabel label={t(`status.${storage.status}`)} icon={{name: "circle", colorIcon: getStatusColor()}}/>
                }
            </TableColumn>
            <TableColumn>{storage.id}</TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{storage.name}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{Formatter.formatDate(storage.creation_ts)}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{bytesToSize(storage.uploaded_file.size_in_bytes)}</TableColumn>
            {!!actions ?
                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                    {storage.isCopiable() &&
                    <IconTooltip
                        icon={Action.COPY.icon}
                        text={textStorages("table.actions.reimport_storage")}
                        onClick={actions.onCopy}
                    />
                    }
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                        items={[
                            {label: textStorages("table.actions.view_storage"), onClick: () => history.push(storage.getRoute())},
                            {label: textCommon(Action.DELETE.labelKey), onClick: actions.onDelete, critical: true}
                        ]}
                    />
                </TableActionColumn> : <></>
            }
        </TableRow>
    );
};

export default StorageTableRow;
